import React from 'react'
import { Svg } from './styles'

export const Logo = props => (
  <a href='https://finanka.mx' target="_self" >
    <Svg width={200.061} height={51.222} {...props}>
      <g data-name="Grupo 1">
        <path
          data-name="Trazado 1"
          d="M20.985 51.222L.014 30.236h9.393l11.578 11.591z"
          fill="#bec8d4"
        />
        <path
          data-name="Trazado 2"
          d="M20.988 41.827l11.589-11.591h9.4L20.988 51.222z"
          fill="#9599a2"
        />
        <path
          data-name="Trazado 3"
          d="M20.988 0l21 20.986h-9.395L20.988 9.38z"
          fill="#bec8d4"
        />
        <path
          data-name="Trazado 4"
          d="M20.986 9.38L9.394 20.986H0L20.986 0z"
          fill="#9599a2"
        />
        <path
          data-name="Trazado 5"
          d="M9.173 25.288l10.184-10.181v4.52L13.7 25.288z"
          fill="#bec8d4"
        />
        <path
          data-name="Trazado 6"
          d="M13.7 25.921l5.657 5.671v4.522L9.173 25.921z"
          fill="#9599a2"
        />
        <path
          data-name="Trazado 7"
          d="M32.811 25.92L22.625 36.099v-4.522l5.657-5.657z"
          fill="#bec8d4"
        />
        <path
          data-name="Trazado 8"
          d="M28.287 25.289l-5.662-5.645v-4.522l10.186 10.167z"
          fill="#9599a2"
        />
        <path
          data-name="Trazado 9"
          d="M16.792 25.494l4.2-4.2 4.2 4.2z"
          fill="#fff"
        />
      </g>
      <g data-name="Grupo 2" fill="#f4f4f4">
        <path
          data-name="Trazado 10"
          d="M60.923 15.257h13.989v3.521H65.09l.008 17.218h-4.171zm6.839 12.423l-1.926-3.845h6.568v3.845z"
        />
        <path
          data-name="Trazado 11"
          d="M83.519 15.277l.01 20.71h-4.32l-.008-20.708z"
        />
        <path
          data-name="Trazado 12"
          d="M92.869 15.273l13.352 19.225v1.479h-4.32l-9.03-13.013.006 13.018h-4.32l-.008-20.71zm9.024 0h4.32l.006 12.376-4.318.022z"
        />
        <path
          data-name="Trazado 13"
          d="M126.426 32.297h-9.468l-1.567 3.67h-4.438l8.481-20.707h4.467l-5.467 13.345h6.478l-2.932-7.1h4.491l5.934 14.455h-4.438z"
        />
        <path
          data-name="Trazado 14"
          d="M140.707 15.251l13.352 19.222v1.478h-4.32l-9.03-13.01.006 13.018h-4.32l-.006-20.708zm9.024 0h4.32l.008 12.389h-4.32z"
        />
        <path
          data-name="Trazado 15"
          d="M175.648 15.232l-12.242 15.893v4.822h-4.32l-.006-20.707h4.32v9.083l6.949-9.087zM168.108 33v-6.847l7.548 9.79h-5.3z"
        />
        <path
          data-name="Trazado 16"
          d="M194.088 32.264h-9.468l-1.567 3.678h-4.438l8.481-20.716h4.467l-5.467 13.344h6.478l-2.932-7.1h4.514l5.908 14.458h-4.432z"
        />
      </g>
    </Svg>
  </a>
)
