import React from "react"
//import { Link } from "gatsby"
import PropTypes from "prop-types"

import {Columns, Column} from 'bloomer';
import {ContainerHeader, ContLogo, ContNavBar, ContRedes} from './styles';
import {Logo} from '../Logo';
import {Redes} from '../Redes';


const Header = ({ siteTitle }) => (
  <header>
    <ContainerHeader isFluid>
      <Columns className="columns-responsive">
        <Column isSize={{mobile: 6, tablet: 6, desktop: 3, widescreen: 3}}>
          <ContLogo>
            <Logo/>
          </ContLogo>
        </Column>
        <Column isSize={{mobile: 6, desktop: 0.5, widescreen: 2.5}} className="no-display-mobile">
          &nbsp;
        </Column>
        <Column isSize={{desktop: 6, widescreen: 4}} className="no-display-mobile">
          <ContNavBar>

          </ContNavBar>
        </Column>
        <Column isSize={{mobile: 6, tablet: 6, desktop: 2, widescreen: 2}}>
          <ContRedes>
            <Redes/>
          </ContRedes>
        </Column>
      </Columns>
    </ContainerHeader>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
