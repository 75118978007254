/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Container } from 'bloomer';
import { GlobalStyleLayout } from './styles';
import { BackImage } from '../BackImage';

import { GlobalStyle } from '../../styles/GlobalStyles'
import { GlobalFonts } from '../../styles/fonts';

import Header from "../Header/index"

import '../../styles/index.css';
import '../../styles/bulma/css/bulma.min.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout">
      <GlobalStyle />
      <GlobalFonts />
      <GlobalStyleLayout/>
      <Header siteTitle={data.site.siteMetadata.title} />

      <Container isFluid className="wrapper-pages">
        {children}
      </Container>

      <BackImage/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
