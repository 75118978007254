import styled, {createGlobalStyle} from 'styled-components'
import { FontAwesomeIcon as FontAwesomeIconA } from '@fortawesome/react-fontawesome';

export const GlobalStyleTarjeta = createGlobalStyle`
  .tarjeta{
    display: flex;
    align-items: center;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    height: 100%;
  }
  @media (min-width: 769px) and (max-width: 1023px){
    height: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: 90%;
  }
  @media(min-width: 1408px){
    height: 90%;
  }
`
export const ContName = styled.div`
  position: relative;
  color: #FFF;
  
  @media(min-width: 300px) and (max-width: 768px){
    font: 64px NotoSansRegular;
    line-height: 58px;
    margin-bottom: 20px;
  }
  @media (min-width: 769px) and (max-width: 1023px){
    font: 90px NotoSansRegular;
    line-height: 90px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font: 90px NotoSansRegular;
    line-height: 90px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font: 90px NotoSansRegular;
    line-height: 90px;
  }
  @media(min-width: 1408px){
    font: 90px NotoSansRegular;
    line-height: 90px;
  }
`
export const ContDegree = styled.div`
  position: relative;
  font: 40px MulishRegular;
  color: #82CCDD;
  
  @media(min-width: 300px) and (max-width: 768px){
    font: 30px MulishRegular;
    line-height: 30px;
    margin-bottom: 20px;
  }
  @media (min-width: 769px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px){
    
  }
`

export const ContDesc = styled.div`
  position: relative;
  margin-top: 20%;
  margin-bottom: 30px;
  color: #FFFFFF;
    
  @media(min-width: 300px) and (max-width: 768px){
    margin-top: 0%;
    width: 100%;
    font: 18px MulishLight;
  }
  @media (min-width: 769px) and (max-width: 1023px){
    margin-top: 20%;
    width: 100%;
    font: 20px MulishLight;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 5%;
    width: 100%;
    font: 20px MulishLight;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 5%;
    width: 100%;
    font: 20px MulishLight;
  }
  @media(min-width: 1408px){
    margin-top: 5%;
    width: 100%;
    font: 20px MulishLight;
  }
`
export const ContBtnContact = styled.div`
  position: relative;
  width: auto;
  padding: 10px 20px;
  color: #82CCDD;
  border: 1px solid #82CCDD;
  font: 16px NotoSansRegular;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > div{
    margin: 10px 5px;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    width: 100%;
    margin-bottom: 15px;
    padding: 5px 10px;
  }
`

export const ContBtnsContact = styled.div`
  position: relative;
  width: 100%;
  
  ${ContBtnContact}{
    margin-right: 10px;
    float: left;
  }
  
`

export const FontAwesomeIconAux = styled(FontAwesomeIconA)`
  font-size: 30px;
`

export const ContPhoto = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Img = styled.img`
  position: relative;
  width: 80%;
  height: auto;
`
