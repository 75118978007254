import styled from 'styled-components'
import { Container } from 'bloomer';

export const ContainerHeader = styled(Container)`
  position: relative;
  height: 80px;
`


export const ContLogo = styled.div`
  position: relative;
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  
  
`

export const ContNavBar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1023px){
    display: none;
  }
`

export const ContRedes = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
