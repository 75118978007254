import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { ContRedes, ContRedesIcon, ALinkFacebook, ALinkTwitter, ALinkInstagram, ALinkLinkedin } from './styles';

export const Redes = props => (
    <ContRedes>
      <ContRedesIcon>
        <ALinkFacebook href="https://facebook.com/FinankaMx" target="_blank">
          <FontAwesomeIcon icon={faFacebookF} />
        </ALinkFacebook>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkTwitter href="https://twitter.com/Finanka2" target="_blank">
          <FontAwesomeIcon icon={faTwitter} />
        </ALinkTwitter>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkInstagram href="https://instagram.com/finanka_" target="_blank">
          <FontAwesomeIcon icon={faInstagram} />
        </ALinkInstagram>
      </ContRedesIcon>
      <ContRedesIcon>
        <ALinkLinkedin href="https://linkedin.com/in/finankamx/" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} />
        </ALinkLinkedin>
      </ContRedesIcon>
    </ContRedes>
)
