import React from 'react'
import {ContSvg} from './styles'
import {Img} from "../Tarjeta/styles";

// width={401} height={399}

export const BackImage = props => (
  <ContSvg>
    <Img src={require(`../../assets/back-image.png`)} alt=""/>
  </ContSvg>
)
