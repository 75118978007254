import { createGlobalStyle } from 'styled-components'

import NotoSansRegularEot from '../fonts/NotoSansRegular.eot';
import NotoSansRegularTtf from '../fonts/NotoSansRegular.ttf';
import NotoSansRegularWoff from '../fonts/NotoSansRegular.woff';
import NotoSansRegularWoff2 from '../fonts/NotoSansRegular.woff2';
import NotoSansRegularSvg from '../fonts/NotoSansRegular.svg';

import MulishRegularEot from '../fonts/MulishRegular.eot';
import MulishRegularTtf from '../fonts/MulishRegular.ttf';
import MulishRegularWoff from '../fonts/MulishRegular.woff';
import MulishRegularWoff2 from '../fonts/MulishRegular.woff2';
import MulishRegularSvg from '../fonts/MulishRegular.svg';

import MulishLightEot from '../fonts/MulishLight.eot';
import MulishLightTtf from '../fonts/MulishLight.ttf';
import MulishLightWoff from '../fonts/MulishLight.woff';
import MulishLightWoff2 from '../fonts/MulishLight.woff2';
import MulishLightSvg from '../fonts/MulishLight.svg';

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'NotoSansRegular';
    src: url(${NotoSansRegularEot});
    src: local('NotoSansRegular'), local('NotoSansRegular'),
        url(${NotoSansRegularWoff2}) format('woff2'),
        url(${NotoSansRegularWoff}) format('woff'),
        url(${NotoSansRegularTtf}) format('truetype'),
        url('${NotoSansRegularSvg}#NotoSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishRegular';
    src: url(${MulishRegularEot});
    src: local('MulishRegular'), local('MulishRegular'),
        url(${MulishRegularWoff2}) format('woff2'),
        url(${MulishRegularWoff}) format('woff'),
        url(${MulishRegularTtf}) format('truetype'),
        url('${MulishRegularSvg}#MulishRegular') format('svg');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'MulishLight';
    src: url(${MulishLightEot});
    src: local('MulishLight'), local('MulishLight'),
        url(${MulishLightWoff2}) format('woff2'),
        url(${MulishLightWoff}) format('woff'),
        url(${MulishLightTtf}) format('truetype'),
        url('${MulishLightSvg}#MulishLight') format('svg');
    font-weight: bold;
    font-style: normal;
  }
`
