import styled from 'styled-components'

export const ContSvg = styled.div`
  position: absolute;
  margin-bottom: 0;
  height: auto;
  
  & > svg,
  & > img{
    width: 100%;
    height: 100%;
  }
  
  @media(min-width: 300px) and (max-width: 768px){
    display: none;
  }
  @media (min-width: 769px) and (max-width: 1023px){
    display: none;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 220px;
    height: 220px;
    margin-top: -157px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 250px;
    height: 250px;
    margin-top: -186px;
  }
  @media(min-width: 1408px){
    width: 300px;
    height: 300px;
    margin-top: -237px;
  }
`
